import { makeStyles } from "@material-ui/core/styles";

import TwitterIcon from "@material-ui/icons/Twitter";
// import TelegramIcon from "@material-ui/icons/Telegram";
// import { Icon, InlineIcon } from "@iconify/react";
import { Icon } from "@iconify/react";
import discordIcon from "@iconify-icons/mdi/discord";
import { Grid } from "@material-ui/core";

import { socialMedia } from "../constants/data/socialMedia";

const useStyles = makeStyles((theme) => ({
  snsIcon: {
    width: "30px",
    height: "30px",

    [theme.breakpoints.down("xs")]: {
      width: "25px",
      height: "25px",
    },
    "&:hover": {
      color: theme.palette.info.main,
    },
    padding: "0 0 0 15px",
  },
}));

const Social = ({ color }) => {
  const classes = useStyles();

  const { twitter, discord } = socialMedia;

  return (
    <div style={{ display: "flex" }}>
      <Grid
        item
        component={"a"}
        target="_blank"
        rel="noreferrer noopener"
        href={twitter}
        style={{ color: "black" }}
      >
        <TwitterIcon
          className={classes.snsIcon}
          // color={color ? "primary" : "secondary"}
        />
      </Grid>
      <Grid
        item
        component={"a"}
        target="_blank"
        rel="noreferrer noopener"
        href={discord}
        style={{ color: "black" }}
      >
        <Icon
          className={classes.snsIcon}
          // color={color ? "primary" : "secondary"}
          icon={discordIcon}
        />
      </Grid>
    </div>
  );
};

export default Social;
