import "./App.css";
import { makeStyles } from "@material-ui/core/styles";
import { Section } from "react-bulma-components";
import { Landing } from "./pages/Landing";
import { Service } from "./pages/Service";
import { Error } from "./pages/Error";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { useWeb3Context } from "./context/Web3Context";

const useStyles = makeStyles((theme) => ({
  progressBar: {
    color: "#ff007a",
  },
  section: {
    paddingBottom: "100px",
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  messageAlert: {
    color: "red",
    // fontFamily: `'Inter var' sans-serif`,
    fontFamily: "RetroGaming",
    fontWeight: "bold",
    fontSize: "13px",
  },
  message: {
    color: "black",
    // fontFamily: `'Inter var' sans-serif`,
    fontFamily: "RetroGaming",
    fontWeight: "bold",
    fontSize: "13px",
  },
  stats: {
    color: "black",
    fontFamily: "RetroGaming",
    fontSize: "20px",
  },
  statsGrid: {
    paddingBottom: "100px",
  },
}));

function App() {
  const classes = useStyles();

  const { walletInfo, ghstBalance } = useWeb3Context();

  return (
    <Section className={classes.section}>
      <Navbar />
      {walletInfo.address === null ? (
        <Landing />
      ) : walletInfo.chainId !== "137" && ghstBalance === undefined ? (
        <Error />
      ) : (
        <Service />
      )}
      <Footer />
    </Section>
  );
}

export default App;
