import React from "react";
import Grid from "@material-ui/core/Grid";

export const Landing = () => {
  return (
    <Grid container justifyContent="center">
      <img
        src={process.env.PUBLIC_URL + "/gotchi-gel.png"}
        alt="Gotchi Logo"
        width="400px"
        height="400px"
      />
    </Grid>
  );
};
