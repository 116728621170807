import { ethers } from "ethers";
import carer_artifacts from "../constants/abi/CareCentreV2.json";
import { addresses } from "../constants/addresses";
import Notify from "bnc-notify";

var notify = Notify({
  dappId: "6aef7963-f1c3-4ac8-83fa-782d4708affc",
  networkId: 137,
});

export const startCare = async (walletInfo, ids) => {
  const carerContract = new ethers.Contract(
    addresses.CARER,
    carer_artifacts.abi,
    walletInfo.signer
  );

  const txn = await carerContract.startCare();

  notify.hash(txn.hash);
  await txn.wait();
};

export const stopCare = async (walletInfo, ids) => {
  const carerContract = new ethers.Contract(
    addresses.CARER,
    carer_artifacts.abi,
    walletInfo.signer
  );

  const txn = await carerContract.stopCare();

  notify.hash(txn.hash);
  await txn.wait();
};

export const isCaring = async (walletInfo) => {
  const carerContract = new ethers.Contract(
    addresses.CARER,
    carer_artifacts.abi,
    walletInfo.signer
  );

  const caringOwners = await carerContract.getCaringOwners();
  const isCaring = caringOwners.includes(walletInfo.address);

  if (isCaring) return true;

  return false;
};

export const getPayingRate = async (walletInfo) => {
  const carerContract = new ethers.Contract(
    addresses.CARER,
    carer_artifacts.abi,
    walletInfo.signer
  );

  const petCount = await carerContract.caringOwnerPetCount(walletInfo.address);
  const rate = await carerContract.calculateRate(petCount);
  const formatted = ethers.utils.formatEther(rate).toString();

  return formatted;
};
