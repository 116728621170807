import { Container } from "@material-ui/core";
import Social from "./Social";
import "../index.css";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  message: {
    color: "black",
    fontFamily: "RetroGaming",
    fontWeight: "bold",
    fontSize: "14px",
    display: "inline",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer>
      <Container
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/poweredbygelato_white.svg"}
          alt="Gelato Powered"
          width="160px"
          height="60px"
          style={{ marginRight: "100px" }}
        />
        <Typography className={classes.message}>
          Join the GotchiCare discord group to get bot updates when your Gotchi
          gets pet!
        </Typography>

        <Social />
      </Container>
    </footer>
  );
};

export default Footer;
