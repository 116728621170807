import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import { makeStyles } from "@material-ui/core/styles";
import DepositForm from "./DepositForm";

import {
  approveOperator,
  unapproveOperator,
  isOperatorApproved,
} from "../helpers/operator";
import { startCare, stopCare, isCaring } from "../helpers/careCentre";
import { useWeb3Context } from "../context/Web3Context";

// import { getRatePerHalfDay } from "../helpers/getRate";
// import { checkIsOwnerCaring, getMaxPets } from "../helpers/getCareInfos";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    paddingBottom: "30px",
  },
  buttonStart: {
    borderRadius: 2,
    height: "50px",
    border: "3px solid",
    borderColor: "#fdeaf1",
    backgroundColor: "#d0ffd0",
    "&:hover": {
      backgroundColor: "#d0ffd0",
      borderColor: "#00d100",
    },
    "&:disabled": {
      color: "651b40",
      backgroundColor: "#fb8985",
      // filter: "grayscale(75%)",
    },
    color: "#00d100",
    fontSize: 18,
    fontFamily: "RetroGaming",
    margin: "5px",
    padding: "0px 30px",
    textTransform: "none",
  },
  buttonStop: {
    borderRadius: 2,
    height: "50px",
    border: "3px solid",
    borderColor: "#fdeaf1",
    backgroundColor: "#fdeaf1",
    "&:hover": {
      backgroundColor: "#fdeaf1",
      borderColor: "#ff007a",
    },
    color: "#ff007a",
    fontSize: 18,
    fontFamily: "RetroGaming",
    margin: "5px",
    padding: "0px 30px",
    textTransform: "none",
  },
  buttonLabel: {
    color: "purple",
    // fontFamily: `'Inter var' sans-serif`,
    fontFamily: "RetroGaming",
    fontWeight: "bold",
    fontSize: "18px",
    marginLeft: "10px",
    marginRight: "10px",
  },
}));

const CareButtons = () => {
  const { walletInfo } = useWeb3Context();

  const classes = useStyles();

  const [isDeposit, setIsDeposit] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isCared, setIsCared] = useState(false);

  const handleUnapproveOperator = async () => {
    await unapproveOperator(walletInfo);
  };

  const handleApproveOperator = async () => {
    await approveOperator(walletInfo);
  };

  const handleStartCare = async () => {
    await startCare(walletInfo);
  };

  const handleStopCare = async () => {
    await stopCare(walletInfo);
  };

  useEffect(() => {
    const setUp = async () => {
      setIsApproved(await isOperatorApproved(walletInfo));
      setIsCared(await isCaring(walletInfo));
    };

    if (walletInfo.address && walletInfo.chainId === "137") {
      setUp();

      const interval = setInterval(() => {
        console.log("Getting button state");
        setUp();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [walletInfo]);

  return (
    <div className={classes.buttonGroup}>
      <>
        <ButtonGroup fullWidth={true}>
          {isApproved ? (
            <Button
              className={classes.buttonStop}
              onClick={handleUnapproveOperator}
            >
              {"REMOVE PET OPERATOR"}
            </Button>
          ) : (
            <Button
              className={classes.buttonStart}
              onClick={handleApproveOperator}
            >
              {"SET PET OPERATOR"}
            </Button>
          )}
          <Button
            className={classes.buttonStart}
            onClick={() => {
              setIsDeposit(true);
            }}
          >
            DEPOSIT/WITHDRAW
          </Button>
          <DepositForm
            isOpen={isDeposit}
            handleClose={() => {
              setIsDeposit(false);
            }}
          />
          {!isCared ? (
            <Button className={classes.buttonStart} onClick={handleStartCare}>
              START PETTING
            </Button>
          ) : (
            <Button className={classes.buttonStop} onClick={handleStopCare}>
              STOP PETTING
            </Button>
          )}
        </ButtonGroup>
      </>
    </div>
  );
};

export default CareButtons;
