import { ethers } from "ethers";
import AavegotchiFacetAbi from "../constants/abi/AavegotchiFacet.json";
import { addresses } from "../constants/addresses";
import Notify from "bnc-notify";

var notify = Notify({
  dappId: "6aef7963-f1c3-4ac8-83fa-782d4708affc",
  networkId: 137,
});

export const unapproveOperator = async (walletInfo) => {
  const aaveContract = new ethers.Contract(
    addresses.AAVE,
    AavegotchiFacetAbi,
    walletInfo.signer
  );

  const txn = await aaveContract.setPetOperatorForAll(
    addresses.OPERATOR,
    false
  );

  notify.hash(txn.hash);
};

export const approveOperator = async (walletInfo) => {
  const aaveContract = new ethers.Contract(
    addresses.AAVE,
    AavegotchiFacetAbi,
    walletInfo.signer
  );

  const txn = await aaveContract.setPetOperatorForAll(addresses.OPERATOR, true);

  notify.hash(txn.hash);
};

export const isOperatorApproved = async (walletInfo) => {
  const aaveContract = new ethers.Contract(
    addresses.AAVE,
    AavegotchiFacetAbi,
    walletInfo.signer
  );

  const isApproved = await aaveContract.isPetOperatorForAll(
    walletInfo.address,
    addresses.OPERATOR
  );

  return isApproved;
};
