import React from "react";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Message from "../components/Message";
import CareButtons from "../components/CareButtons";

import { makeStyles } from "@material-ui/core/styles";
import { useWeb3Context } from "../context/Web3Context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    paddingBottom: "10px",
    // backgroundColor: "#F7F0F5",
  },
  cardTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minHeight: "60px",
  },
  buttonStop: {
    borderRadius: 6,
    height: "40px",
    width: "180px",
    border: "1px solid",
    borderColor: "#fdeaf1",
    backgroundColor: "#fdeaf1",
    "&:hover": {
      backgroundColor: "#fdeaf1",
      borderColor: "#ff007a",
    },
    color: "#ff007a",
    fontSize: 18,
    fontFamily: "RetroGaming",
    padding: "0px 30px",
    textTransform: "none",
  },
  buttonStart: {
    borderRadius: 6,
    height: "40px",
    border: "1px solid",
    borderColor: "#fdeaf1",
    backgroundColor: "#d0ffd0",
    "&:hover": {
      backgroundColor: "#d0ffd0",
      borderColor: "#00d100",
    },
    color: "#00d100",
    fontSize: 18,
    fontFamily: "RetroGaming",
    padding: "0px 30px",
    textTransform: "none",
  },
  authTitle: {
    color: "black",
    // fontFamily: `'Inter var' sans-serif`,
    fontFamily: "RetroGaming",
    fontWeight: "bold",
    fontSize: "15px",
  },
  authSubTitle: {
    marginLeft: "12px",
    color: "black",
    fontFamily: "RetroGaming",
    fontWeight: 500,
    fontSize: "16px",
  },
  cardInfoTitle: {
    marginLeft: "12px",
    color: "black",
    fontFamily: "RetroGaming",
    fontWeight: 300,
    fontSize: "13px",
  },
  cardInfoBody: {
    marginLeft: "12px",
    color: "#b83253",
    fontFamily: "RetroGaming",
    fontWeight: 300,
    fontSize: "14px",
  },
  cardActions: {
    display: "flex",
    flex: 1,
    paddingTop: "20px",
    paddingBottom: "20px",
    justifyContent: "center",
  },
}));

export const Service = () => {
  const classes = useStyles();

  const { gotchiObjs } = useWeb3Context();

  const formatTime = (_lastInteracted) => {
    const lastInteracted = parseInt(_lastInteracted.toString() + "000");
    const d = new Date(lastInteracted);

    let datestring =
      d.getDate() +
      "-" +
      (d.getMonth() + 1) +
      "-" +
      d.getFullYear() +
      " " +
      d.getHours() +
      ":" +
      d.getMinutes();

    return datestring;
  };

  return (
    <div>
      {gotchiObjs === undefined ? (
        <Grid container justifyContent="center">
          <h1>Loading...</h1>
        </Grid>
      ) : gotchiObjs.length === 0 ? (
        <>
          <CareButtons />
          <Grid container justifyContent="center">
            <h1>No Gotchis Found...</h1>
          </Grid>
        </>
      ) : (
        <>
          <CareButtons />
          <Message />
          <Grid container spacing={5} justifyContent="center">
            {gotchiObjs.map((gotchi) => (
              <Grid key={gotchi.id} item xs={8} sm={8} md={3}>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardContent>
                      <Box className={classes.cardTitle}>
                        <Typography className={classes.authTitle}>
                          {gotchi.name}
                        </Typography>
                        <Typography className={classes.authSubTitle}>
                          ({gotchi.kinship})
                        </Typography>
                      </Box>
                    </CardContent>
                    <CardMedia
                      src={`data:image/svg+xml;base64,${btoa(gotchi.svg)}`}
                      component="img"
                    />
                  </CardActionArea>
                  <CardActions classes={{ root: classes.cardActions }}>
                    <Typography>
                      <span className={classes.cardInfoTitle}>Last pet:</span>
                    </Typography>
                    <span className={classes.cardInfoBody}>
                      {formatTime(gotchi.lastInteracted)}
                    </span>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </div>
  );
};
