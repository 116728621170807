import { ethers } from "ethers";
import erc20_artifacts from "../constants/abi/ERC20.json";
import { addresses } from "../constants/addresses";
import { roundDown } from "../utils/utils";

export const getGHSTBalance = async (walletInfo) => {
  if ((walletInfo.chainId = "137")) {
    const signer = walletInfo.signer;
    const ghstContract = new ethers.Contract(
      addresses.GHST,
      erc20_artifacts.abi,
      signer
    );

    const balance = await ghstContract.balanceOf(walletInfo.address);
    const formattedBalance = Number(ethers.utils.formatEther(balance));
    const roundedDownBalance = roundDown(formattedBalance);

    return roundedDownBalance;
  }
};
