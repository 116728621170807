export const shortenAddress = (addr, chars = 4) => {
  if (addr.length >= 10) {
    return `${addr.substring(0, chars + 2)}...${addr.substring(42 - chars)}`;
  }
  return addr;
};

export const roundDown = (val) => {
  const roundedDown = Math.floor(val * 100) / 100;

  return roundedDown;
};
