import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  DialogActions,
} from "@material-ui/core";
import styled from "styled-components";

import {
  deposit,
  withdraw,
  approve,
  isGhstApproved,
  getTreasuryBalance,
} from "../helpers/treasury";
import { roundDown } from "../utils/utils";
import { useWeb3Context } from "../context/Web3Context";

const InputWrap = styled.div`
  font-family: "RetroGaming";
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding-bottom: 20px;
`;

const Label = styled.h3`
  font-family: "RetroGaming";
  color: #651b40;
`;

const Notice = styled.p`
  font-family: "RetroGaming";
  padding: 20px 0px 20px 0px;
  /* color: #b83253; */
  color: #fc6665;
`;

const DepositForm = ({ isOpen, handleClose }) => {
  const { walletInfo, ghstBalance } = useWeb3Context();
  const [ghstAmount, setGhstAmount] = useState();
  const [hasAllowance, setHasAllowance] = useState();
  const [treasuryBalance, setTreasuryBalance] = useState();

  const handleApprove = async () => {
    await approve(walletInfo, ghstAmount);
    setHasAllowance(true);
  };

  const handleDeposit = async () => {
    await deposit(walletInfo, ghstAmount);
  };

  const handleWithdraw = async () => {
    await withdraw(walletInfo, ghstAmount);
  };

  useEffect(() => {
    const updateBalance = async () => {
      const _treasuryBalance = ethers.utils.formatEther(
        await getTreasuryBalance(walletInfo)
      );
      const roundedDownBalance = roundDown(_treasuryBalance);

      setTreasuryBalance(roundedDownBalance);
    };

    const setUp = async () => {
      setHasAllowance(await isGhstApproved(walletInfo));
      await updateBalance();
    };

    if (walletInfo.address && walletInfo.chainId === "137") {
      setUp();

      const interval = setInterval(() => {
        console.log("Getting form balance");
        setUp();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [walletInfo]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Label>Manage Account</Label>
          <Notice>
            {`Wallet Balance: ${ghstBalance} GHST`}
            <br />
            {`Account Balance: ${treasuryBalance} GHST`}
          </Notice>

          <InputWrap>
            <TextField
              autoFocus
              autoComplete="off"
              variant="filled"
              type="number"
              error={true}
              onChange={(e) => {
                setGhstAmount(e.target.value);
              }}
            />
            <p
              style={{
                fontFamily: "RetroGaming",
                color: "#651b40",
                padding: "0px 15px 0 15px",
              }}
            >
              GHST
            </p>
          </InputWrap>
        </DialogContent>
        <DialogActions>
          {hasAllowance ? (
            <Button
              onClick={() => {
                handleDeposit();
              }}
              style={{
                fontFamily: "RetroGaming",
                color: "#651b40",
                padding: "0px 15px 0 15px",
              }}
            >
              Deposit From Wallet
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleApprove();
              }}
              style={{
                fontFamily: "RetroGaming",
                color: "#651b40",
                padding: "0px 15px 0 15px",
              }}
            >
              Approve
            </Button>
          )}
          <Button
            onClick={() => {
              handleWithdraw();
            }}
            style={{
              fontFamily: "RetroGaming",
              color: "#651b40",
              padding: "0px 15px 0 15px",
            }}
          >
            Withdraw From Account
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DepositForm;
