import { ethers } from "ethers";
import treasury_artifacts from "../constants/abi/TreasuryV2.json";
import GHST_artifacts from "../constants/abi/ERC20.json";
import { addresses } from "../constants/addresses";
import Notify from "bnc-notify";

var notify = Notify({
  dappId: "6aef7963-f1c3-4ac8-83fa-782d4708affc",
  networkId: 137,
});

export const deposit = async (walletInfo, depositAmount) => {
  const treasuryContract = new ethers.Contract(
    addresses.TREASURY,
    treasury_artifacts.abi,
    walletInfo.signer
  );

  const txn = await treasuryContract.depositToAccount(
    ethers.utils.parseEther(depositAmount),
    walletInfo.address
  );

  notify.hash(txn.hash);
  await txn.wait();
};

export const withdraw = async (walletInfo, withdrawAmount) => {
  const treasuryContract = new ethers.Contract(
    addresses.TREASURY,
    treasury_artifacts.abi,
    walletInfo.signer
  );

  const txn = await treasuryContract.withdrawFromAccount(
    ethers.utils.parseEther(withdrawAmount)
  );

  notify.hash(txn.hash);
  await txn.wait();
};

export const approve = async (walletInfo) => {
  const ghstContract = new ethers.Contract(
    addresses.GHST,
    GHST_artifacts.abi,
    walletInfo.signer
  );

  const txn = await ghstContract.approve(
    addresses.TREASURY,
    ethers.utils.parseEther("100000000")
  );

  notify.hash(txn.hash);
  await txn.wait();
};

export const isGhstApproved = async (walletInfo) => {
  const ghstContract = new ethers.Contract(
    addresses.GHST,
    GHST_artifacts.abi,
    walletInfo.signer
  );

  const allowance = await ghstContract.allowance(
    walletInfo.address,
    addresses.TREASURY
  );

  if (allowance >= ethers.utils.parseEther("100")) return true;

  return false;
};

export const getTreasuryBalance = async (walletInfo) => {
  const treasuryContract = new ethers.Contract(
    addresses.TREASURY,
    treasury_artifacts.abi,
    walletInfo.signer
  );

  const balance = await treasuryContract.userBalance(walletInfo.address);

  return balance;
};
