import { React, useState, useEffect } from "react";
import { ethers } from "ethers";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
// import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";

import { shortenAddress, roundDown } from "../utils/utils";

import { useWeb3Context } from "../context/Web3Context";

import { getTreasuryBalance } from "../helpers/treasury";

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "transparent",
    boxShadow: "none",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  toolbar: {
    minHeight: 90,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: theme.spacing(3),
    },
  },
  logoBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  toolbarTitle: {
    letterSpacing: 1.25,
    color: "black",
    paddingLeft: "8px",
    fontFamily: "RetroGaming",
    fontWeight: "bold",
    fontSize: "30px",
  },
  menuButtons: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  item: {
    padding: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(3),
    },
  },
  walletButton: {
    borderRadius: 6,
    height: "50px",
    border: "2px solid",
    borderColor: "#fdeaf1",
    fontFamily: "RetroGaming",

    backgroundColor: "#fdeaf1",
    "&:hover": {
      backgroundColor: "#fdeaf1",
      borderColor: "#ff007a",
    },
    color: "#ff007a",
    padding: "0px 16px",
    textTransform: "none",
  },
  legacyButton: {
    borderRadius: 6,
    height: "50px",
    border: "2px solid",
    borderColor: "#fdeaf1",
    fontFamily: "RetroGaming",

    backgroundColor: "#fdeaf1",
    "&:hover": {
      backgroundColor: "#fdeaf1",
      borderColor: "#ff007a",
    },
    color: "#ff007a",
    padding: "0 16px",
    marginRight: "100px",
    textTransform: "none",
  },
  plusButton: {
    minWidth: "40px",
    mminHeight: "40px",
    borderRadius: 16,
    fontSize: "16px",
    fontWeight: 500,
    color: "black",
  },
  etherTitle: {
    color: "black",
    // fontFamily: `'Inter var' sans-serif`,
    fontFamily: "RetroGaming",

    fontWeight: 500,
    fontSize: "16px",
  },

  walletAddressButton: {
    borderRadius: 16,
    border: "1px solid",
    borderColor: "#f7f8fa",
    backgroundColor: "#f7f8fa",
    "&:hover": {
      borderColor: "#fdeaf1",
      backgroundColor: "#f9fafc",
    },
    color: "black",
    textTransform: "none",
    // fontFamily: `'Inter var' sans-serif`,
    fontFamily: "RetroGaming",
    fontWeight: 500,
    fontSize: "16px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  walletBox: {
    height: "40px",
    display: "flex",
    flexDirection: "row",
    borderRadius: 16,
    backgroundColor: "#edeef2",
    color: "#ff007a",
    paddingLeft: "8px",
    textTransform: "none",
    alignItems: "center",
    fontFamily: `'Inter var' sans-serif`,
    fontWeight: 500,
    fontSize: "16px",
  },
  GHSTBox: {
    marginLeft: "10px",
    marginRight: "10px",
    display: "flex",
    flex: "row",
    alignItems: "center",
  },
}));

const Navbar = () => {
  const classes = useStyles();

  const { connectWeb3, walletInfo } = useWeb3Context();

  const [treasuryBalance, setTreasuryBalance] = useState();

  useEffect(() => {
    const updateBalance = async () => {
      const _treasuryBalance = ethers.utils.formatEther(
        await getTreasuryBalance(walletInfo)
      );
      const roundedDownBalance = roundDown(_treasuryBalance);

      setTreasuryBalance(roundedDownBalance);
    };
    if (walletInfo.address && walletInfo.chainId === "137") {
      updateBalance();

      const interval = setInterval(() => {
        console.log("Fetching treasury balance");
        updateBalance();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [walletInfo, connectWeb3]);

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.logoBox}>
          {/* <Link to="/"> */}
          <img
            src={process.env.PUBLIC_URL + "favicon.ico"}
            alt="Gotchi Logo"
            width="40px"
            height="40px"
          />
          {/* </Link> */}
          <Typography
            component="h2"
            variant="h5"
            className={classes.toolbarTitle}
          >
            GotchiCare
          </Typography>
        </Box>
        {walletInfo.address === null ? (
          <Button className={classes.walletButton} onClick={connectWeb3}>
            Connect to a wallet
          </Button>
        ) : (
          <Box className={classes.walletBox}>
            <Box className={classes.GHSTBox}> Account balance: </Box>
            <Box className={classes.GHSTBox}>
              <Typography className={classes.etherTitle}>
                {treasuryBalance} GHST
              </Typography>
            </Box>
            <Button className={classes.walletAddressButton}>
              {walletInfo.address === ""
                ? "Not available"
                : shortenAddress(walletInfo.address)}
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
