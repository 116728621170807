import React from "react";
import Grid from "@material-ui/core/Grid";

export const Error = () => {
  return (
    <Grid container justifyContent="center">
      <h1>Wrong Network</h1>
    </Grid>
  );
};
