import axios from "axios";
import { ethers } from "ethers";

const AAVEGOTCHI_SUBGRAPH =
  "https://subgraph.satsuma-prod.com/tWYl5n5y04oz/aavegotchi/aavegotchi-core-matic/api";
const AAVEGOTCHI_SVG_SUBGRAPH =
  "https://api.thegraph.com/subgraphs/name/froid1911/aavegotchi-svg";

export const getGotchiInfos = async (address) => {
  const lowerAddress = address.toLowerCase();
  try {
    const result = await axios.post(AAVEGOTCHI_SUBGRAPH, {
      query: `
            {
              user(id: "${lowerAddress}"){
                gotchisOwned(first: 1000, orderBy: id, orderDirection: asc ){
                  id,
                  name,
                  kinship,
                  lastInteracted
                }
              }
            }
            `,
    });

    const data = result.data.data.user.gotchisOwned;
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

// export const getGotchiIds = async (address) => {
//   const lowerAddress = address.toLowerCase();
//   try {
//     const result = await axios.post(AAVEGOTCHI_SUBGRAPH, {
//       query: `
//               {
//                 user(id: "${lowerAddress}"){
//                   gotchisOwned(first: 1000, orderBy: id, orderDirection: asc ){
//                     id
//                   }
//                 }
//               }
//               `,
//     });

//     const data = result.data.data.user.gotchisOwned;
//     return data;
//   } catch (error) {
//     console.error(error);
//   }
// };

export const getGotchiSvgs = async (id) => {
  const id_number = parseInt(id);
  try {
    const result = await axios.post(AAVEGOTCHI_SVG_SUBGRAPH, {
      query: `
              {
                aavegotchi(id: ${id_number}) {
                  svg
                }
              }
              `,
    });

    const data = result.data.data.aavegotchi;
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getSvgs = async (walletInfo, gotchiInfo) => {
  const AavegotchiDiamond = "0x86935F11C86623deC8a25696E1C19a8659CbF95d";
  const SvgFacetAbi = [
    "function getAavegotchiSvg(uint256 _tokenId) public view returns (string memory ag_)",
  ];

  const signer = walletInfo.signer;
  const svgContract = new ethers.Contract(
    AavegotchiDiamond,
    SvgFacetAbi,
    signer
  );

  var svgs = [];

  var calls = [];
  gotchiInfo.forEach((gotchi) => {
    calls.push(svgContract.getAavegotchiSvg(Number(gotchi.id)));
  });
  svgs = await Promise.all(calls);

  return svgs;
};

export const test = async () => {
  console.log("HERE");
  const furu =
    "https://polygon-graph.furucombo.app/subgraphs/name/furucombo/trevi-furugelato";
  try {
    const result = await axios.post(furu, {
      query: `
      {
        tasks(first: 1000, orderBy: updatedAt, orderDirection: desc) {
          id
          creator
          stakingToken
          resolver
          executionData
          executionCount
          status
          createdAt
          updatedAt
          txs(orderBy: updatedAt, orderDirection: desc) {
            id
            type
            hash
            block
            createdAt
            updatedAt
          }
        }
      }
            `,
    });

    const data = result.data;
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

test();
