import { useState, createContext, useContext, useEffect } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { getGotchiInfos, getGotchiSvgs } from "../queries/getGotchis";
import { getGHSTBalance } from "../queries/getBalance";

const Web3Context = createContext(null);

const initWeb3Modal = () => {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: "INFURA_ID",
      },
    },
  };

  const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions,
  });

  return web3Modal;
};

const Web3ContextProvider = ({ children }) => {
  const [walletInfo, setWalletInfo] = useState({
    chainId: null,
    address: null,
    signer: null,
    ethersProvider: null,
  });
  const [gotchiInfos, setGotchiInfos] = useState();
  const [gotchiSvgs, setGotchiSvgs] = useState();
  const [gotchiObjs, setGotchiObjs] = useState();
  const [ghstBalance, setGhstBalance] = useState();

  useEffect(() => {
    // Blockchain ------------------------------------------------
    //   https://polygon-rpc.com/

    const getBalance = async () => {
      const balance = await getGHSTBalance(walletInfo);
      setGhstBalance(balance);
    };

    // Subgraph ------------------------------------------------

    const queryGotchis = async () => {
      const info = await getGotchiInfos(walletInfo.address);
      //**change */
      // const info = await getGotchiInfos(
      //   // "0xE2557794A70332b55CC5eE2b655B2facf7c6218d"
      //   "0x60ed33735c9c29ec2c26b8ec734e36d5b6fa1eab"
      // );
      if (info.length > 0) {
        const svgsCall = info.map((_info) => getGotchiSvgs(_info.id));
        const svgs = await Promise.all(svgsCall);

        let objs = [];
        info.forEach((_info, count) => {
          objs[count] = {
            ..._info,
            svg: svgs[count].svg,
          };
        });

        console.log("Info: ", info);
        console.log("Svgs: ", svgs);
        console.log("Objs: ", objs);
        setGotchiObjs(objs);
        setGotchiSvgs(svgs);
        setGotchiInfos(info);
      } else {
        setGotchiObjs([]);
      }
    };

    if (walletInfo.address && walletInfo.chainId === 137) {
      console.log("Querying subgraph");
      queryGotchis();
      getBalance();

      const interval = setInterval(() => {
        console.log("Fetching wallet balance");
        getBalance();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [walletInfo]);

  // Web3Modal ------------------------------------------------

  const connectWeb3 = async () => {
    const web3Modal = initWeb3Modal();

    await loadWeb3Modal(web3Modal);
  };

  const loadWeb3Modal = async (web3Modal) => {
    try {
      const provider = await web3Modal.connect();

      const walletInfo = await updateWalletInfo(provider);

      await subscribeProvider(provider);

      return walletInfo;
    } catch (e) {
      console.log("Could not get a wallet connection", e);
      return;
    }
  };

  const subscribeProvider = async (provider) => {
    provider.on("accountsChanged", () => {
      updateWalletInfo(provider);
      console.log("accountsChanged");
    });

    provider.on("chainChanged", () => {
      updateWalletInfo(provider);
      console.log("chainChanged");
    });
  };
  //  ------------------------------------------------

  const updateWalletInfo = async (provider) => {
    const ethersProvider = new ethers.providers.Web3Provider(provider);
    const signer = ethersProvider.getSigner();
    const [address, chainId] = await Promise.all([
      signer.getAddress(),
      signer.getChainId(),
    ]);

    setWalletInfo({ chainId, address, signer, ethersProvider });
    console.log("chainId: ", chainId);
    console.log("address: ", address);
  };

  return (
    <Web3Context.Provider
      value={{
        walletInfo: walletInfo,
        connectWeb3: connectWeb3,
        gotchiInfos: gotchiInfos,
        gotchiSvgs: gotchiSvgs,
        gotchiObjs: gotchiObjs,
        ghstBalance: ghstBalance,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

const useWeb3Context = () => useContext(Web3Context);

export { Web3ContextProvider, useWeb3Context };
