import "../index.css";
import { useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { getPayingRate } from "../helpers/careCentre";
import { useWeb3Context } from "../context/Web3Context";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  message: {
    color: "black",
    fontFamily: "RetroGaming",
    fontWeight: "bold",
    fontSize: "14px",
    display: "inline",
  },
  highlight: {
    color: "#ff007a",
  },
}));

const Message = () => {
  const classes = useStyles();
  const { walletInfo } = useWeb3Context();
  const [payingRate, setPayingRate] = useState();

  useEffect(() => {
    const setUp = async () => {
      const _payingRate = await getPayingRate(walletInfo);
      setPayingRate(_payingRate);
    };

    if (walletInfo.address && walletInfo.chainId === "137") {
      setUp();

      const interval = setInterval(() => {
        console.log("Getting paying rate");
        setUp();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [walletInfo]);

  return (
    <div>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography className={classes.message}>
          <Grid
            container
            className={classes.root}
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={16}
          >
            <Grid xs={6}>
              <h2>Pricing</h2>
              <p className={classes.highlight}>{`Your rate now : ${
                payingRate * 2
              } GHST / Day`}</p>
              ~ {payingRate * 2 * 30} GHST a month
              {/* <ul>
                <li>
                  <p className={classes.highlight}>
                    {payingRate * 2 * 30} GHST a month
                  </p>
                </li>
                <li>
                  <p className={classes.highlight}>
                    0.25 GHST/pet after one month
                  </p>
                </li>
                <li>
                  <p className={classes.highlight}>
                    0.20 GHST/pet after two months
                  </p>
                </li>
              </ul> */}
            </Grid>
            <Grid xs={6}>
              <h2>Instructions</h2>
              <ol>
                <li>
                  <p>
                    Approve GotchiCare contract to act as a <i>Pet Operator</i>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    Deposit sufficient GHST to your account to cover for
                    petting. ** Your Gotchis will not be pet if you run out of
                    GHST.
                    <br />
                  </p>
                </li>
                <li>
                  <p>Start a session and enjoy your life!</p>
                </li>
              </ol>
            </Grid>
          </Grid>
        </Typography>
      </Container>
    </div>
  );
};

export default Message;
